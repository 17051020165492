import { Dynamic } from "package:/components/elements/Dynamic";
import { Icon } from "package:/components/elements/Icon";
import { Link } from "package:/components/elements/Link";
import { useApi } from "package:/composables/useApi";
import { defineComponent, useLanguage, useTranslations } from "#imports";
import { SocialLinks } from "./SocialLinks";
import { Picture } from "~/components/elements/Picture";

export const Footer = defineComponent(
  () => {
    const t = useTranslations();
    const lang = useLanguage();

    const data = useApi((api) => {
      return api.page.pagesNavigation();
    });

    return () => (
      <footer class="content pt-20 pb-24 max-lg:px-0">
        <div class="col-span-12 xl:col-span-10 xl:col-start-2">
          <div class="grid grid-cols-2 gap-4 gap-y-10">
            <div class="flex flex-col">
              <div class="flex gap-4">
                <SocialLinks variant="dark" />
              </div>
              <div class="mt-8 text-green-200 text-xs">{t("navigation.copyright")}</div>
            </div>

            <div class="flex gap-12 text-base">
              <Dynamic data={data}>
                {data.data.value?.data.map((category) => {
                  return (
                    <div class="space-y-4" key={`navigation-category-${category.title}`}>
                      <h2 class="mb-8 font-bold text-green-200">{category.title}</h2>
                      <div class="space-y-2">
                        {category.children.map((item) => {
                          return (
                            <Link
                              key={`navigation-item-${item.slug}`}
                              class="flex w-full items-center justify-between font-light underline"
                              variant="plain"
                              to={{
                                name: "lang-slug",
                                params: { lang: lang.value, slug: item.slug },
                              }}
                            >
                              <span>{item.title}</span>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </Dynamic>
              <div>
                <h2 class="mb-8 font-bold text-green-200">{t("navigation.contact")}</h2>
                <div class="space-y-2">
                  <Link
                    class="flex w-full items-center gap-4 text-link"
                    href={`tel:+${t("navigation.phone")}`}
                    variant="inline"
                  >
                    <Icon name="phone" aria-hidden="true" class="text-[1.1em]" />
                    <span>{t("navigation.phone")}</span>
                  </Link>
                  <Link
                    class="flex w-full items-center gap-4 text-link"
                    href={`mailto:${t("navigation.mail")}`}
                    variant="inline"
                  >
                    <Icon name="mail" aria-hidden="true" class="text-[1.1em]" />
                    <span>{t("navigation.mail")}</span>
                  </Link>
                </div>
              </div>
            </div>

            <span />

            <div class="cols-2 text-base">
              <h2 class="mb-8 font-bold text-green-200">{t("misc.sponsors")}</h2>
              <Picture height={45} src="/logos/logos.png" alt={t("misc.sponsors")} />
            </div>
          </div>
        </div>
      </footer>
    );
  },
  {
    name: "Footer",
  },
);
